import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <div className="about-content">
        <h1>Neat. ❤️</h1>
      </div>
    </div>
  );
};

export default About;