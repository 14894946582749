import React, { useState } from 'react';
import ClassList from '../components/ClassList';
import './Home.css';

const Home = () => {

  return (
    <div className="home">
      <h1>Hi.</h1>
    </div>
  );
};

export default Home;

